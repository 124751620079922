<template>
  <div class="guide-main pdHeader">
    <common-header class="red"></common-header>

    <!-- 下一步 start -->
    <div class="guide-form" v-if="step === 1">
      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="text"
            placeholder="请输入手机号"
            class="input"
            maxlength="11"
            v-model="phone"
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="text"
            placeholder="请输入验证码"
            class="input"
            maxlength="6"
            v-model="verifyCode"
            @blur="fixScroll"
          />
        </div>
        <div class="yzm-box" @click="send">
          <span :class="`yzm ${verifyClass}`">{{ verifyText }}</span>
        </div>
      </div>

      <a href="javascript:" class="guide-large-btn mt10" @click="stepHandle"
        >下一步</a
      >
    </div>
    <!-- 下一步 end -->

    <!-- 完成 start -->
    <div class="guide-form" v-else-if="step === 2">
      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="password"
            placeholder="请设置新密码"
            class="input"
            v-model="pwd"
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="password"
            placeholder="请再次输入密码"
            class="input"
            v-model="againPwd"
            @blur="fixScroll"
          />
        </div>
      </div>

      <a href="javascript:" class="guide-large-btn mt10" @click="submit"
        >保存</a
      >
    </div>
    <div class="fix guide-text tc">
      <router-link to="/guide/login">用户登录</router-link>
      <router-link to="/guide/register">用户注册</router-link>
    </div>
    <!-- 完成 end -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { isPhone, isCode, fixScroll } from "@/utils/utils";
import { getPreKey } from "@/utils/codeVerify";

let timer = 60;
let inter;
export default {
  name: "Forget",
  data() {
    this.fixScroll = () => fixScroll();

    return {
      step: 1,
      // 验证码需要 start
      verifyClass: "",
      verifyText: "获取验证码",
      phone: "",
      verifyCode: "",
      // 验证码需要 end
      pwd: "",
      againPwd: ""
    };
  },
  mounted() {},
  methods: {
    ...mapActions("account", ["getValidate", "modifyPwd"]),
    // 获取验证码 start
    send() {
      if (this.verifyClass === "disabled") {
        return;
      }
      if (this.phone === "") {
        this.$toast("手机号不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      this.sendTimer();
      inter = setInterval(this.sendTimer, 1000);

      getPreKey(
        {
          phone: this.phone,
          type: 2
        },
        v => {
          console.log(v);
          this.verifyCode = v.verifyCode || "";
        },
        () => {
          this.clearTimer();
        }
      );
    },
    clearTimer() {
      timer = 60;
      this.verifyClass = "";
      this.verifyText = "获取验证码";
      clearInterval(inter);
    },
    sendTimer() {
      timer--;
      if (timer < 1) {
        this.verifyClass = "";
        this.verifyText = "获取验证码";
        clearInterval(inter);
      } else {
        this.verifyClass = "disabled";
        this.verifyText = timer + "s";
      }
    },
    // 获取验证码 end
    stepHandle() {
      if (this.phone === "") {
        this.$toast("手机号码不能为空");
        return;
      }

      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      if (this.verifyCode === "") {
        this.$toast("验证码不能为空");
        return;
      }
      if (!isCode(this.verifyCode)) {
        this.$toast("验证码格式不正确");
        return;
      }

      this.getValidate({
        code: this.verifyCode,
        phone: this.phone,
        type: 2
      }).then(() => {
        this.step = 2;
      });
    },
    submit() {
      if (this.pwd === "") {
        this.$toast("新密码不能为空");
        return;
      }

      if (this.againPwd === "") {
        this.$toast("确认密码不能为空");
        return;
      }

      if (this.againPwd !== this.pwd) {
        this.$toast("两次密码不一致");
        return;
      }

      const da = {
        phone: this.phone,
        pwd: this.pwd,
        verifyCode: this.verifyCode
      };

      this.modifyPwd(da).then(() => {
        this.$toast("操作成功");
        clearInterval(inter);
        const _this = this;
        setTimeout(() => {
          _this.$router.push("/guide");
        }, 2500);
      });
    }
  }
};
</script>
<style lang="less" scoped>
.guide-text {
  padding: 0 0.3rem;

  a {
    margin: 0 0.4rem;
  }
}
</style>
